/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initBannerSlider();
        this.initMatrixBlock();
        this.setcontactparam();
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            $('#mobile-cta').css('bottom', -120);
            $.data(this, 'scrollTimer', setTimeout(function() {
                $('#mobile-cta').css('bottom', 0);
            }, 1000));
        });
        $(window).resize(() => {
            $('#mobile-cta').css('bottom', 0);
        });
        console.log('App initialized');
    }

    setcontactparam()
    {
        if ($('.contact-form').length) {
            let enq_id = $('.contact-form').data('enq');
            if (enq_id != '') {
                $('#contact-us-field-i-would-like-to-enquire-about-input').val(enq_id);
            }
            let clinic = $('.contact-form').data('clinic');
            if (clinic != '') {
                $('#contact-us-field-clinic-if-known-input').val(clinic);
                $("#contact-us-field-clinic-if-known-input").prop("readonly", true);
            }
            let site_id = $('.contact-form').data('currsite');
            if (site_id != '') {
                $('#contact-us-field-request-from').val(site_id);
            }
        }
        if ($('.book-appoint').length) {
            let site_id = $('.book-appoint').data('currsite');
            if (site_id != '') {
                $('#book-appointment-field-request-from').val(site_id);
            }
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem);
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    initBannerSlider() {
        if ($('.banner-slider .banner-slide').length > 1) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.banner-slider').slick({
                    autoplay: true,
                    fade: true,
                    arrows: false,
                    infinite: true,
                    slidesToShow: 1,
                    autoplaySpeed: 10000,
                    speed: 5000,
                    rows: 0,
                });
            });
        }
    }

    initMatrixBlock() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrixblock" */ './components/matrixblock.js').then(matrixblock => {
                new matrixblock.Matrixblock;
            });
        }
    }
}

export default App;